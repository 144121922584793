<template>
   <single-column-layout>
       <token-detail
            v-if="study"
            :projectTitle="studyTitle | doubleQuotes"
            :tokenId="studyId"
            :projectId="project.id"
            :projectAuthor="project.author"
            :authorWebsite="project.authorWebsite"
            dateMinted="December 23, 2021"
            mintPrice="0"
            :showDescription="false"
            :description="text.studyDescription"
            :copyRight="project.copyRight"
            :projectDescription="project.description"
            :imageSrc="study.thumbnailImageUrl"
            :imagePreviewUrl="study.previewImageUrl"
            :nftDescriptionOne="text.nftDescriptionOne"
            :nftDescriptionTwo="text.nftDescriptionTwo"
            :loader="isLoading"
            :stashhLink="true"
            :isStudy="true"
        >
        <button 
            v-if="privateImage" 
            class="download_study" 
            @click="downloadPrivateImage"
        >
            Download High-Res
        </button>
       </token-detail>
   </single-column-layout>
</template>

<script>
import api from "@/service/api";
import SingleColumnLayout from '../layouts/SingleColumnLayout.vue';
import TokenDetail from '../components/TokenDetail.vue';
import { onAccountAvailable, getAddress, refContract } from '@stakeordie/griptape.js';
import { initContracts } from "@/utils";

export default {
    name: 'StudyDetails',
    components: {
        SingleColumnLayout,
        TokenDetail
    },
    data(){
        return {
            address: null,
            projectId:'',
            studyId:'',
            study:{
                alias:"",
                thumbnailImageUrl:"",
                previewImageUrl:""
            },
            project:{
                title:"",
                id:"",
                author:"",
                copyRight:"",
                authorWebsite:""
            },
            privateImage:'',
            nftDossier:{
                owner: '',
                public_metadata:{
                    extension:{
                        description:""
                    }
                }
            },
            isOwner: false,
            studyTitle: '',
            text: {
                studyDescription: '',
                nftDescriptionOne: 'This NFT celebrates and commemorates the creative process that led to The Big Bang. It also serves as a pre-mint ticket, one of 256 NFTs (16 Studies X 16 editions) that grants the owner early access to mint an NFT from The Big Bang collection.',
                nftDescriptionTwo: 'This is a non-generative NFT with an edition size of 16. The public image is 1000x1000 pixels, and the high-res private version -- only accessible by the collector -- is 4000x4000 pixels. Created with Adobe Illustrator and Adobe Photoshop by Emergent Properties co-founder Emil Corsillo, December 2021 in Los Angeles and Boston.'
            },
            isLoading: false,
        }
    },
    async mounted(){
        await initContracts();
        await this.getProjectInfo()
        window.scrollTo({ top: 0 });
        onAccountAvailable( async () => {
            this.address = getAddress();
        });
        await this.getOnChainInfo();
        await this.getDescription();
        this.setStudyText();
    },
    filters: {
        doubleQuotes: function (value) {
            if (!value) return ''
            return value.replaceAll("'",'"')
        }
    },
    methods:{
        async getProjectInfo(){
            this.isLoading = true;
            try {
                this.projectId = this.$route.params.projectId;
                this.studyId = this.$route.params.studyId;
    
                this.study = await api.projects.studies.get(this.projectId,this.studyId);
                this.project = this.study.project;
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;
            }
        },
        async getOnChainInfo() {
            this.isLoading = true;
            const studiesContract = refContract(process.env.VUE_APP_STUDIES_CONTRACT);  
            try {
                const { nft_dossier } = await studiesContract.getNftDossier(this.studyId);
                this.nftDossier = nft_dossier;
                this.privateImage = this.nftDossier?.private_metadata?.extension?.media[0]?.url
                this.isOwner = nft_dossier?.owner === this.address;
                this.studyTitleValidation();
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;
            }
        },
        async getDescription() {   
            this.isLoading = true;
            const studiesContract = refContract(process.env.VUE_APP_STUDIES_CONTRACT);    
            try {
                const { nft_dossier } = await studiesContract.getNftDossier(this.studyId);
                this.nftDossier = nft_dossier;
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false;  
            }
        },
        studyTitleValidation () {
            
            if(!this.address) this.isOwner = false;

            if( this.isOwner ) {
                this.studyTitle = this.study?.alias;
            } else {
                this.studyTitle = this.study?.alias.slice(0, -19)
            }
        },
        downloadPrivateImage(){
            window.open(this.privateImage,'_blank')
        },
        setStudyText () {
            let Alltext = this.nftDossier.public_metadata.extension.description;

            //Match where the text is diferent.
            let result = Alltext.match(/This\sNFT\scelebrates/);
            
            //Adding only the Study description
            this.text.studyDescription = Alltext.substr(0, (result.index));
        }
    }
}
</script>
<style lang="scss">
.download_study {
    width: 184px;
    padding: 11px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 700;
    font-family: Roboto Condensed;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0px;
    line-height:24px;
}
</style>
