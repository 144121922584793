<template>
    <page>
        <column :mode=mode number= "1">
            <slot></slot>
        </column>
    </page>
</template>

<script>
export default {
    name: "SingleColumnLayout",
    props: {
        mode: {
            default: "normal",
            type: String
        }
    }

}
</script>

<style>

</style>
